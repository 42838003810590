import { url_get_param, url_add_param, url_remove_param } from '../../util/methods';

$(document).on('turbolinks:load', function () {

  var $priceList = $('.js-product-variantPriceList');

  if ($priceList.length > 0) {
    var $priceCart = $('.js-price-cart');
    var $addToCartBtn = $('.js-product-addToCart');
    var $addToCartForm = $addToCartBtn.parent('form');
    var $variantContainer = $('.js-variant-container');
    var $variantPrices = $('.js-product-variantPrice');
    var queryStr = window.location.search;
    var varId = url_get_param('variant');

    $variantPrices.on('change', function(e){
      var $this = $(this);
      var price = $this.data('price');
      var variantId = $.trim($this.val());

      if ($this.is(':checked')) {
        $priceCart.text(price);
        $addToCartForm.attr('action', function(_, action){
          var baseAction = url_remove_param(action, 'variant');
          return url_add_param(baseAction, 'variant', variantId);
        });

        $variantContainer.removeClass('active-variant');
        $variantContainer.addClass('active-variant');
      }
    });

    if (queryStr && varId){
      $('#variant-' + varId).trigger('click');
    }
  }

});

import { isTouchDevice } from '../../util/touchDevice';

$(document).on('turbolinks:load', function () {
  var $creatorDropdownToggle= $('.js-creator-dropdown-toggle')
  var $creatorDropdownMenu = $('.creator-dropdown-menu')
  var hideDropdownTimeout;

  function setupCreatorDropdownMouseEvents() {
    $creatorDropdownToggle.on('mouseover', function(){
      clearTimeout(hideDropdownTimeout);
      $creatorDropdownMenu.dropdown('show')
    });

    $creatorDropdownToggle.on('mouseleave', function(e){
      hideDropdownTimeout = setTimeout(function(){
        var relatedTarget = e.relatedTarget;

        if (!relatedTarget || (!$(relatedTarget).closest($creatorDropdownToggle).length)) {
          $creatorDropdownMenu.dropdown('hide');
        }
      }, 150);
    })
  }
  
  if (!isTouchDevice() && 
  !window.location.pathname.includes('/admin') &&
  !window.location.pathname.includes('/creator')){
    setupCreatorDropdownMouseEvents();
  }

  $creatorDropdownMenu.on('click', function(){ 
    $creatorDropdownMenu.dropdown('hide');
  })
});
